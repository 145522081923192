import React from "react";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import "../../Share/Style.css";
import AboutCard from "./AboutCard";
import HomeCard from "../../Share/HomeCard";
import PageTitle from "../../Share/PageTitle";

const AboutTwo = () => {
  const { local, serviceArray } = UseData();

  return (
    <section>
      <PageTitle title="About"></PageTitle>
      {/* End pagetitle */}

      <div className=" bg-white dark:bg-[#111111] lg:rounded-2xl">
        <div data-aos="fade">
          <div className=" px-2 pt-12 sm:px-5 md:px-10 md:py-12 lg:px-14 ">
            {/* About page title */}
            <h2 className="after-effect hidden after:left-52 lg:block">
              About Me
            </h2>
            <div className="lg:hidden">
              {/* Sidebar personal information for mobile devices */}
              <HomeCard />
              <div className=" mb-12 items-center md:gap-10 md:pt-[30px] lg:hidden ">
                <h2 className="after-effect mb-5 after:left-52">About Me</h2>
                <div className="col-span-12 space-y-2.5">
                  <div className="lg:mr-16">
                    <p className="leading-7  text-gray-lite dark:text-color-910">
                      I use my experience and knowledge in software development
                      to provide effective and innovative solutions using
                      technology. With my problem-solving skills and analytical
                      thinking, I can achieve efficient results even in complex
                      projects. Adapting to teamwork and reaching common goals
                      using my project management skills are important to me. I
                      have a strong interest in continuous learning and staying
                      updated with the latest developments in technology.
                    </p>
                    <p className="mt-2.5 leading-7 text-gray-lite dark:text-color-910">
                      Adapting to teamwork and reaching common goals using my
                      project management skills are important to me. I have a
                      strong interest in continuous learning and staying updated
                      with the latest developments in technology.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden grid-cols-12 items-center pt-4 md:gap-10 md:pt-[30px] lg:grid ">
              <div className="col-span-12 space-y-2.5">
                <div className="lg:mr-16">
                  <p className="leading-7  text-gray-lite dark:text-color-910">
                    I use my experience and knowledge in software development to
                    provide effective and innovative solutions using technology.
                    With my problem-solving skills and analytical thinking, I
                    can achieve efficient results even in complex projects.
                  </p>
                  <p className="mt-2.5 leading-7 text-gray-lite dark:text-color-910">
                    Adapting to teamwork and reaching common goals using my
                    project management skills are important to me. I have a
                    strong interest in continuous learning and staying updated
                    with the latest developments in technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* End about descriptions */}

          <div className="  px-2 pb-12 sm:px-5 md:px-10 lg:px-14 ">
            <h3 className="pb-5 text-[35px] font-medium dark:text-white">
              What I do!
            </h3>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-2 ">
              {/* Experience information  */}

              {serviceArray.map((item) => (
                <AboutCard key={Math.random()} item={item} local={local} />
              ))}
            </div>
          </div>
          {/* service provide end */}

          {/* Common Footer call here */}
          <Footer bg={"#FFFF"} />
        </div>
      </div>
    </section>
  );
};

export default AboutTwo;
